import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';
import './Testimonials.css';
import profilePic1 from '../../img/Profile1.jpg';
import profilePic2 from '../../img/Profile2.jpg';
import profilePic3 from '../../img/Profile3.jpg';
import profilePic4 from '../../img/Profile4.jpg';
import profilePic5 from '../../img/Profile5.jpg';

import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
const Testimonials = () => {
    const clients = [
        {
            img: profilePic1,
            review: 'Hello, All! Arthur is very talented web-developer. We liked our work done by him!'
        },
        {
            img: profilePic2,
            review: 'Cool stuff he did. Thank you, Arthur!'
        },
        {
            img: profilePic3,
            review: 'We recommend Arthur to everyone. Very strong developer.'
        },
        {
            img: profilePic4,
            review: 'Try to work with Arthur. We were very glad that he done.'
        },
        {
            img: profilePic5,
            review: 'Cool programmer. And nice man. Thank you, Arthur'
        },
    ]
  return (
    <div className="t-wrapper" id="Testimonials">
        <div className="t-heading">
            <span>Clients always get </span>
            <span>Exceptional Work </span>
            <span>from me...</span>
            <div className="blur t-blur1" style={{background: ' rgb(238 210 255)'}}></div>
            <div className="blur t-blur2" style={{background:'skyblue'}}></div>
        </div>
        <Swiper modules={[Pagination]}
                slidesPerView={1}
                pagination={{clickable: true}}
        >
            {clients.map((client, index) => {
                return (
                    <SwiperSlide key={index}>
                        <div className="testimonials">
                        <img src={client.img}/>
                        <span>{client.review}</span>
                        </div>
                        
                    </SwiperSlide>
                )
            })}
        </Swiper>

    </div>
  )
}

export default Testimonials