import Navbar from "./Components/NavBar/Navbar";
import { Intro } from "./Components/Intro/Intro";
import Services from "./Components/Services/Services";
import Experience from "./Components/Experience/Experience";
import Works from "./Components/Works/Works";
import Testimonials from "./Components/Testimonials/Testimonials";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import { ThemeContext } from "./Context";
import { useContext } from "react";
import './App.css';
function App() {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="App" style={{background: darkMode? 'black':'',
          color: darkMode? 'white' : ''}}>
   
    <Navbar/>
    <Intro/>
    <Services/>
    <Experience/>
    <Works/>
    <Testimonials/>
    <Contact/>
    <Footer/>
    </div>
  );
}

export default App;
