import React from 'react'
import './Intro.css'
import GitHub from '../../img/github.png'
import LinkedIn from '../../img/linkedin.png'
import Instagram from '../../img/instagram.png'
import Vector1 from '../../img/Vector1.png'
import Vector2 from '../../img/Vector2.png'
import boy2 from '../../img/boy2.png'
import thumbup from '../../img/thumbup.png'
import crown from '../../img/crown.png'
import glassesimoji from '../../img/glassesimoji.png'
import FloatingDiv from '../FloatingDiv/FloatingDiv'
import { motion} from 'framer-motion'
export const Intro = () => {
const transition = {duration: 3.5, type: 'spring'};
  return (
    <div className="intro">
        <div className='i-left'>
            <div className='i-name'>
                <span>Hi! I Am</span>
                <span> Arthur Blinov </span>
                <span>
                    Full-Stack Developer with experience
                    in full-stack development, producting the 
                    Quality Work
                </span>
            </div>
            <a href="https://www.linkedin.com/in/arthur-blinov-5a65b3208/">
                <button className="button i-button" style={{height: '2.4rem', width:'8rem'}}>Hire me</button>
            </a>
           
            <div className="i-icons">
                <a href='https://github.com/arthurBlinov'><img src={GitHub} alt=""/></a>
                <a href='https://www.linkedin.com/in/arthur-blinov-5a65b3208/'><img src={LinkedIn} alt=""/></a>
                <a href='http://www.instagram.com'><img src={Instagram} alt=""/></a>
                
            </div>
        </div>
        <div className="i-right">
        <img src={Vector1} alt=""/>
        <img src={Vector2} alt=""/>
        <img src={boy2} alt=""/>
        <motion.img 
        initial={{left: '-36%'}}
        animate={{left: '-24%'}}
        transition={transition}
        src={glassesimoji}/>
        <motion.div className='floating-div' initial={{top: '-4%', left:'68%'}} animate={{left: '62%'}} transition={transition}>
                    <FloatingDiv image={crown} txt1='Web' txt2='Developer'/>
                </motion.div>
        <div style={{top: '50%' }} className='floating-div'>
            <FloatingDiv image={thumbup} txt1='Best Design' txt2='Award'/>
        </div>
        <div className='blur' style={{background: 'rgb(238 210 255)' }}>
        </div>
        <div className='blur' style={{background: '#C1F5FF', top:'17rem', width: '21rem', height: '11rem', left:'-9rem'}}>
        </div>
        </div>
    </div>
  )
}
