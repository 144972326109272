import React from 'react'
import Toggle from '../Toggle/Toggle'
import { Link } from 'react-scroll';
import './Navbar.css'
const Navbar = () => {
  return (
    <div className="n-wrapper" id="NavBar">
        <div className="n-left">
            <div className="n-name">Arthur</div>
           <Toggle/>
        </div>
        <div className="n-right">
            <div className="n-list">
                <ul style={{listStyleType: 'none'}}>
                <Link spy={true} to='Navbar' smooth={true} activeClass='activeClass'>
                <li>Home</li>
                </Link>
                <Link spy={true} to='Services' smooth={true}>
                <li>Services</li>
                </Link>
                <Link spy={true} to='Experience' smooth={true}>
                <li>Experience</li>
                </Link>
                <Link spy={true} to='Portfolio' smooth={true}>
                <li>Portfolio</li>
                </Link>
                <Link spy={true} to='Testimonials' smooth={true}>
                <li>Testimonials</li>
                </Link>
                   
                </ul>
            </div>
            <a href="https://www.facebook.com/blinov.arthur">
            <button className='button n-button' style={{height: '2.4rem', width:'8rem'}}>Contact</button>
            </a>
            
        </div>
    </div>
  )
}

export default Navbar