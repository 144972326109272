import React from 'react'
import './Works.css'
import Upwork from '../../img/Upwork.png'
import Fiverr from '../../img/fiverr.png'
import Amazon from '../../img/amazon.png'
import Shopify from '../../img/Shopify.png'
import Facebook from '../../img/Facebook.png'
import {motion} from 'framer-motion';

const Works = () => {
  return (
   <div className="works">
    <div className="awesome">
            <span>Worked for All these</span>
            <span>Brands & Clients</span>
           
            <a href="https://www.linkedin.com/in/arthur-blinov-5a65b3208/"><button style={{height: '2.4rem', width:'8rem'}} className='button s-button'>Hire Me</button></a>
            
            
            <div className="blur s-blur1" style={{background: '#ABF1FF94'}}></div>
        </div>
        <div className="w-right">
            <motion.div 
            initial={{rotate:45}}
            animate={{rotate: 0}}
            viewport={{margin: '-40px'}}
            transition={{duration: 7, type: 'spring'}}
            className="w-mainCircle">
                <div className="w-secCircle">
                    <img src={Upwork}/>
                </div>
                <div className="w-secCircle">
                    <img src={Fiverr}/>
                </div>
                <div className="w-secCircle">
                    <img src={Amazon}/>
                </div>
                <div className="w-secCircle">
                    <img src={Shopify}/>
                </div>
                <div className="w-secCircle">
                    <img src={Facebook}/>
                </div>
            </motion.div>
            <div className="w-backCircle blueCircle"></div>
            <div className="w-backCircle yellowCircle"></div>
        </div>
   </div>
  )
}

export default Works