import React from 'react'
import './Services.css'
import HeatEmpoji from '../../img/heartemoji.png';
import Glasses from '../../img/glasses.png';
import Humble from '../../img/humble.png';
import Card from '../Card/Card';
import myCV from './myCV.pdf';
import {motion} from 'framer-motion';
const Services = () => {
    const transition = {duration: 3, type: 'spring'}
  return (
   <div className="services" id="Services">

        <div className="awesome">
            <span>My Awesome</span>
            <span>Services</span>
            <span>
                Javascript, Css, ReactJs, React Native, HTML, etc
                <br/>
                Web-Development, Application Bulding 
                
            </span>
            <a href={myCV} download>

            <button className='button s-button' style={{height: '3.2rem', width:'8rem'}}>Download CV</button>
            </a>
            <div className="blur s-blur1" style={{background: '#ABF1FF94'}}></div>
        </div>
        <div className="cards">
            <motion.div initial={{left: '18rem'}} animate={{left: '30rem'}} transition={transition}>
                <Card
                    emoji={HeatEmpoji}
                    heading={'Design'}
                    detail={'Photoshop, Adobe'}
                />
            </motion.div>
            <div style={{ top:'12rem', left: '-4rem'}}>
                <Card
                    emoji={Glasses}
                    heading={'Developer'}
                    detail={'Html, Css, Javascript, React'}
                />
            </div>
            <motion.div style={{ top:'19rem'}} initial={{left: '12rem'}} animate={{left: '27rem'}} transition={transition}>
                <Card
                    emoji={Humble}
                    heading={'UI/UX'}
                    detail={'UI/UX building'}
                />
            </motion.div>
            <div className="blur s-blur-2" style={{background:'rgb(238 210 255)'}}></div>
        </div>


   </div>
  )
}

export default Services