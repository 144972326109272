import React, {useContext} from 'react'
import './Toggle.css';
import Sun from '@iconscout/react-unicons/icons/uil-sun';
import Moon from  '@iconscout/react-unicons/icons/uil-moon';
import { ThemeContext } from '../../Context';

const Toggle = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    const handleClick =() => {
        theme.dispatch({type: 'toggle'})
    }
  return (
    <div className="toggle">
        <Moon/>
        <Sun/>
        <div className='t-button' onClick={handleClick}
        style={darkMode? {left: '2px'} : {right: '2px'}}>

        </div>
    </div>
  )
}

export default Toggle